
import {
  defineComponent
} from 'vue'

export default defineComponent({
  name: 'ExpandTd',
  props: {
    expand: {
      type: Boolean,
    },
  },
})
